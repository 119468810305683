import assert from 'assert';

import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';

import { SKIP_CONTENT_SETTINGS_KEYS, SKIP_CONTENT_SETTING_KEY_TO_ANALYTICS_TYPE, SkipContentSettings } from '../settings';

export const getSkipContentSettingsWithAllValueSetAs = (value: boolean) =>
  SKIP_CONTENT_SETTINGS_KEYS.reduce((acc, key) => {
    acc[key] = value;
    return acc;
  }, {} as Partial<SkipContentSettings>) as SkipContentSettings;

// We don't call `enforcePremiumConstraint` from the setter actions below because skip content can be enabled for the 1st doc for free users.
// What we do is just enforce it during hydration level so that we don't end up in a bad state.
export const enforcePremiumConstraintForSkipContentSettings = (settings: SkipContentSettings) => {
  const newSettings = { ...settings };
  const isPremium = subscriptionStoreSelectors.getIsPremium();

  for (const key of SKIP_CONTENT_SETTINGS_KEYS) {
    if (!isPremium && newSettings[key]) {
      newSettings[key] = false;
    }
  }

  return newSettings;
};

export const toggleSkipContent = (key: keyof SkipContentSettings) => (value: SkipContentSettings[typeof key]) => {
  const contentBundlerOptionsFacade = usePlaybackStore.getState().contentBundlerOptionsFacade;
  assert(contentBundlerOptionsFacade !== undefined, 'contentBundlerOptionsFacade is not defined');

  contentBundlerOptionsFacade.updateIndividualSetting(key, value);
  logAnalyticsEvent(AnalyticsEventKey.listeningSettingsSkipContentClicked, {
    type: SKIP_CONTENT_SETTING_KEY_TO_ANALYTICS_TYPE[key],
    value: value ? 'on' : 'off'
  });
};

export const updateSkipSettings = (skipSettings: SkipContentSettings) => {
  const contentBundlerOptionsFacade = usePlaybackStore.getState().contentBundlerOptionsFacade;
  assert(contentBundlerOptionsFacade !== undefined, 'contentBundlerOptionsFacade is not defined');
  contentBundlerOptionsFacade.updateSkipSettings(skipSettings);
};

export const setAllSkipSettingsValueTo = (value: boolean) => {
  const skipSettings = getSkipContentSettingsWithAllValueSetAs(value);
  updateSkipSettings(skipSettings);
  logAnalyticsEvent(AnalyticsEventKey.listeningSettingsSkipContentClicked, {
    type: 'all',
    value: value ? 'on' : 'off'
  });
};
