import CloseIcon from 'components/newListeningExperience/icons/CloseIcon';
import React, { useCallback, useMemo } from 'react';
import SimpleBar from 'simplebar-react';

import { autoSkipContentSettingsUIConfigs, displaySettingsUIConfigs } from 'modules/listening/features/settings/settingItemsUIConfig';
import { DisplaySettings, ListeningSettings, SKIP_CONTENT_SETTINGS_KEYS, SkipContentSettings } from 'modules/listening/features/settings/settings';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import { isAndroid, isIOS, isMobile } from 'utils/browser';
import { cn } from 'utils/cn';

import Switch from '../../shared/Switch';
import { UpgradeIcon } from '../icons';
import AutoSkipContentIcon from '../icons/AutoSkipContentIcon';
import EnhancedSkippingIcon from '../icons/EnhancedSkipping';
import SettingItem from '../SettingItem';
import ZoomControl, { ZoomControlProps } from '../ZoomControl';
import AppStoreButton from './mobileAppBanners/AppStoreButton';
import GooglePlayButton from './mobileAppBanners/GooglePlayButton';

export enum AutoSkipContentUnlockedReason {
  Premium = 'premium',
  FreeFirstDocument = 'free-first-document'
}

export type SettingsProps = {
  autoSkipContentUnlocked: boolean;
  autoSkipContentUnlockedReason?: AutoSkipContentUnlockedReason;
  className?: string;
  onAllSkipSettingsChange: (checked: boolean) => void;
  onAutoSkipContentChange: (settingName: keyof SkipContentSettings, checked: boolean) => void;
  onClose: () => void;
  onSettingChange: (settingName: keyof DisplaySettings, checked: boolean) => void;
} & Omit<ListeningSettings, 'zoomPercentage'> &
  ZoomControlProps;

const Settings: React.FC<SettingsProps> = ({
  autoSkipContentUnlocked,
  className,
  onAllSkipSettingsChange,
  onAutoSkipContentChange,
  onClose,
  onSettingChange,
  ...props
}) => {
  const isSkipAllChecked = useMemo(() => {
    if (!autoSkipContentUnlocked) return false;
    return SKIP_CONTENT_SETTINGS_KEYS.every(key => props[key]);
  }, [autoSkipContentUnlocked, props]);

  const onClick = useCallback(() => showUpsellModal(UpsellModalType.ContextualSkipHeadersFooters), []);

  const onSkipAllChange = useCallback(
    (checked: boolean) => {
      if (!autoSkipContentUnlocked) return;
      onAllSkipSettingsChange(checked);
    },
    [autoSkipContentUnlocked, onAllSkipSettingsChange]
  );

  return (
    <div className={cn('bg-surface-primary h-[calc(100%-53px)] flex flex-col shadow-spl-100 mt-[53px]', className)}>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="flex bg-bg-primary justify-between relative items-center px-spl-7 py-spl-6">
          <div className="text-heading-5 grow text-center text-text-primary">Settings</div>
          <button className="text-icon-primary absolute right-spl-7">
            <CloseIcon onClick={onClose} />
          </button>
        </div>
        <SimpleBar className="flex-shrink min-h-0">
          <div className="flex flex-col gap-spl-7">
            <ZoomControl {...props} />
            <div>
              <h3 className="text-heading-5 text-text-primary px-spl-7 pb-spl-4">Display</h3>
              {Object.entries(displaySettingsUIConfigs).map(([key, config]) => {
                const typesafeKey = key as keyof DisplaySettings;
                return (
                  <SettingItem
                    fillIconBackground
                    key={key}
                    name={config.settingDisplayName()}
                    checked={props[typesafeKey]}
                    onChange={checked => onSettingChange(typesafeKey, checked)}
                    icon={config.icon}
                  />
                );
              })}
            </div>
            <div>
              <div className="flex justify-between px-spl-7 pb-spl-4">
                <h3 className="text-heading-5 text-text-primary">Auto Skip Content</h3>
                {autoSkipContentUnlocked && (
                  <div className="flex gap-spl-4">
                    <span className="text-text-secondary text-body-6 pt-[3px]">Skip all</span>
                    <Switch onChange={onSkipAllChange} checked={isSkipAllChecked} />
                  </div>
                )}
                {!autoSkipContentUnlocked && (
                  <button className="flex items-center gap-spl-3" onClick={onClick}>
                    <UpgradeIcon />
                    <span
                      className="text-heading-6"
                      style={{
                        background: 'linear-gradient(283deg, #FFA82F 0.25%, #FF795B 100%)',
                        backgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        WebkitBackgroundClip: 'text'
                      }}
                    >
                      Upgrade
                    </span>
                  </button>
                )}
              </div>
              {autoSkipContentUnlocked && props.autoSkipContentUnlockedReason === AutoSkipContentUnlockedReason.FreeFirstDocument && (
                <div className="w-full flex justify-center items-center">
                  <div className="flex w-[368px] h-9 bg-bg-secondary padding-spl-4 gap-spl-3 rounded-250 items-center justify-start">
                    <AutoSkipContentIcon className="ml-[10px]" />
                    <span className="text-text-primary text-subheading-6">You can skip content in your first document.</span>
                  </div>
                </div>
              )}

              <div className="flex items-center justify-between px-spl-7 py-spl-4">
                <div className="flex items-center gap-spl-4">
                  <div className="p-spl-2 rounded-100">
                    <EnhancedSkippingIcon />
                  </div>

                  <div className="flex flex-col justify-center items-start gap-1">
                    <div className="flex items-start gap-[6px]">
                      <div className="text-subheading-6 text-text-primary">Enhanced Skipping</div>
                      <div className="flex items-start gap-[2px] p-[2px_4px] rounded-100 bg-[_#EEF] text-xs font-normal text-[#6B78FC]">Beta</div>
                    </div>
                    <div className="text-xs font-normal text-text-secondary">Process document with AI</div>
                  </div>
                </div>
                <div>
                  <Switch
                    locked={!autoSkipContentUnlocked}
                    checked={props['enhancedSkipping']}
                    onChange={checked => onAutoSkipContentChange('enhancedSkipping', checked)}
                  />
                </div>
              </div>

              {Object.entries(autoSkipContentSettingsUIConfigs).map(([key, item]) => {
                const typesafeKey = key as keyof SkipContentSettings;
                return (
                  <SettingItem
                    key={typesafeKey}
                    name={item.settingDisplayName()}
                    checked={props[typesafeKey]}
                    onChange={checked => onAutoSkipContentChange(typesafeKey, checked)}
                    icon={item.icon}
                    state={autoSkipContentUnlocked ? 'default' : 'locked'}
                  />
                );
              })}
            </div>

            {/* Margin bottom to let settings scrollable with enough margin on devices with limited height */}
            <div style={{ height: '8rem', width: '100%', background: 'transparent' }} />
          </div>
        </SimpleBar>
      </div>

      {isMobile() && (
        <div className="bg-surface-primary border-t border-border-primary p-spl-7 shadow-[0_-4px_8px_rgba(0,0,0,0.08)]">
          <div className="flex flex-col items-stretch gap-spl-7">
            <div className="flex flex-col items-center">
              <span className="text-subheading-5 text-text-primary">Get the Speechify {isIOS() ? 'iPhone' : 'Android'} App</span>
              <span className="text-body-6 text-text-secondary">The best way to listen and enjoy your files</span>
            </div>
            {isIOS() && <AppStoreButton />}
            {isAndroid() && <GooglePlayButton />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
