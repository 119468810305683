import { FC } from 'react';

import { useListeningSettingsStore } from 'modules/listening/features/settings/settingsStore';
import { cn } from 'utils/cn';

const AppStoreButton: FC = () => {
  const isDarkModeEnabled = useListeningSettingsStore(s => s.darkMode);
  return (
    <a
      href="https://apps.apple.com/us/app/speechify-audio-text-reader/id1209815023"
      target="_blank"
      rel="noopener noreferrer"
      className={cn('flex flex-col items-center rounded-[11px]', isDarkModeEnabled ? 'bg-white' : 'bg-[#1E1E1E]')}
    >
      <svg width="328" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill={isDarkModeEnabled ? '#1E1E1E' : 'white'}>
          <path d="M188.276 21.1209V23.641H186.696V25.2937H188.276V30.9092C188.276 32.8267 189.143 33.5936 191.325 33.5936C191.709 33.5936 192.074 33.548 192.394 33.4932V31.8588C192.12 31.8862 191.946 31.9044 191.645 31.9044C190.668 31.9044 190.239 31.4479 190.239 30.407V25.2937H192.394V23.641H190.239V21.1209H188.276Z" />
          <path d="M197.953 33.7306C200.857 33.7306 202.637 31.7857 202.637 28.5625C202.637 25.3576 200.847 23.4036 197.953 23.4036C195.049 23.4036 193.26 25.3576 193.26 28.5625C193.26 31.7857 195.04 33.7306 197.953 33.7306ZM197.953 31.9866C196.246 31.9866 195.287 30.7357 195.287 28.5625C195.287 26.4077 196.246 25.1476 197.953 25.1476C199.651 25.1476 200.619 26.4077 200.619 28.5625C200.619 30.7266 199.651 31.9866 197.953 31.9866Z" />
          <path d="M204.161 33.5389H206.124V27.6677C206.124 26.2707 207.174 25.3302 208.662 25.3302C209.009 25.3302 209.593 25.3941 209.758 25.4489V23.5132C209.548 23.4584 209.173 23.431 208.881 23.431C207.585 23.431 206.48 24.1432 206.197 25.1202H206.051V23.5954H204.161V33.5389Z" />
          <path d="M214.632 25.0746C216.084 25.0746 217.034 26.0881 217.079 27.6495H212.057C212.167 26.0972 213.18 25.0746 214.632 25.0746ZM217.07 30.8544C216.705 31.6305 215.892 32.0597 214.705 32.0597C213.135 32.0597 212.121 30.9548 212.057 29.2108V29.1013H219.079V28.4165C219.079 25.2937 217.408 23.4036 214.641 23.4036C211.838 23.4036 210.058 25.4215 210.058 28.5991C210.058 31.7766 211.802 33.7306 214.65 33.7306C216.924 33.7306 218.513 32.6349 218.96 30.8544H217.07Z" />
          <path d="M175.402 29.8669C175.553 32.3086 177.588 33.8701 180.616 33.8701C183.853 33.8701 185.878 32.2329 185.878 29.6208C185.878 27.5672 184.724 26.4315 181.922 25.7785L180.418 25.4094C178.638 24.993 177.919 24.4346 177.919 23.4598C177.919 22.2295 179.036 21.4251 180.711 21.4251C182.301 21.4251 183.399 22.2106 183.598 23.4693H185.661C185.538 21.1696 183.512 19.5513 180.739 19.5513C177.758 19.5513 175.771 21.1696 175.771 23.6018C175.771 25.6081 176.897 26.8006 179.367 27.3779L181.127 27.8038C182.935 28.2296 183.73 28.8542 183.73 29.8953C183.73 31.1067 182.481 31.9868 180.777 31.9868C178.951 31.9868 177.683 31.1634 177.503 29.8669H175.402Z" />
          <path d="M155.066 23.431C153.714 23.431 152.546 24.1067 151.943 25.2389H151.797V23.5954H149.907V36.8442H151.87V32.0323H152.025C152.546 33.0823 153.669 33.7032 155.084 33.7032C157.595 33.7032 159.193 31.7218 159.193 28.5625C159.193 25.4033 157.595 23.431 155.066 23.431ZM154.509 31.941C152.865 31.941 151.833 30.6444 151.833 28.5717C151.833 26.4898 152.865 25.1933 154.518 25.1933C156.18 25.1933 157.175 26.4624 157.175 28.5625C157.175 30.6718 156.18 31.941 154.509 31.941Z" />
          <path d="M166.062 23.431C164.711 23.431 163.542 24.1067 162.939 25.2389H162.793V23.5954H160.903V36.8442H162.866V32.0323H163.022C163.542 33.0823 164.665 33.7032 166.08 33.7032C168.591 33.7032 170.189 31.7218 170.189 28.5625C170.189 25.4033 168.591 23.431 166.062 23.431ZM165.505 31.941C163.862 31.941 162.83 30.6444 162.83 28.5717C162.83 26.4898 163.862 25.1933 165.514 25.1933C167.176 25.1933 168.171 26.4624 168.171 28.5625C168.171 30.6718 167.176 31.941 165.505 31.941Z" />
          <path d="M146.385 33.5389H148.637L143.706 19.8825H141.426L136.495 33.5389H138.672L139.93 29.9142H145.135L146.385 33.5389ZM142.457 22.362H142.618L144.596 28.135H140.47L142.457 22.362Z" />
          <path d="M137.812 9.58196V16.1699H140.191C142.154 16.1699 143.291 14.96 143.291 12.8554C143.291 10.7827 142.145 9.58196 140.191 9.58196H137.812ZM138.835 10.5133H140.077C141.442 10.5133 142.25 11.3807 142.25 12.8691C142.25 14.3802 141.455 15.2385 140.077 15.2385H138.835V10.5133Z" />
          <path d="M146.772 16.2657C148.224 16.2657 149.114 15.2933 149.114 13.6817C149.114 12.0792 148.22 11.1022 146.772 11.1022C145.321 11.1022 144.426 12.0792 144.426 13.6817C144.426 15.2933 145.316 16.2657 146.772 16.2657ZM146.772 15.3938C145.919 15.3938 145.439 14.7683 145.439 13.6817C145.439 12.6043 145.919 11.9742 146.772 11.9742C147.622 11.9742 148.105 12.6043 148.105 13.6817C148.105 14.7637 147.622 15.3938 146.772 15.3938Z" />
          <path d="M156.696 11.1981H155.714L154.829 14.992H154.751L153.728 11.1981H152.788L151.765 14.992H151.692L150.802 11.1981H149.807L151.176 16.1699H152.185L153.208 12.5084H153.285L154.313 16.1699H155.331L156.696 11.1981Z" />
          <path d="M157.826 16.1699H158.808V13.2617C158.808 12.4856 159.269 12.0016 159.995 12.0016C160.721 12.0016 161.068 12.3988 161.068 13.1978V16.1699H162.049V12.9512C162.049 11.7688 161.437 11.1022 160.328 11.1022C159.579 11.1022 159.086 11.4355 158.844 11.9879H158.771V11.1981H157.826V16.1699Z" />
          <path d="M163.595 16.1699H164.577V9.25781H163.595V16.1699Z" />
          <path d="M168.268 16.2657C169.72 16.2657 170.61 15.2933 170.61 13.6817C170.61 12.0792 169.715 11.1022 168.268 11.1022C166.816 11.1022 165.922 12.0792 165.922 13.6817C165.922 15.2933 166.812 16.2657 168.268 16.2657ZM168.268 15.3938C167.415 15.3938 166.935 14.7683 166.935 13.6817C166.935 12.6043 167.415 11.9742 168.268 11.9742C169.117 11.9742 169.601 12.6043 169.601 13.6817C169.601 14.7637 169.117 15.3938 168.268 15.3938Z" />
          <path d="M173.535 15.4257C173.001 15.4257 172.613 15.1655 172.613 14.7181C172.613 14.2798 172.923 14.0469 173.608 14.0013L174.822 13.9237V14.3391C174.822 14.9555 174.275 15.4257 173.535 15.4257ZM173.284 16.2521C173.937 16.2521 174.48 15.969 174.777 15.4714H174.854V16.1699H175.799V12.7732C175.799 11.7231 175.096 11.1022 173.85 11.1022C172.722 11.1022 171.919 11.6501 171.818 12.5038H172.768C172.877 12.1523 173.256 11.9514 173.804 11.9514C174.475 11.9514 174.822 12.2482 174.822 12.7732V13.2023L173.476 13.28C172.293 13.353 171.627 13.8689 171.627 14.7637C171.627 15.6722 172.325 16.2521 173.284 16.2521Z" />
          <path d="M179.13 16.2521C179.815 16.2521 180.395 15.9279 180.692 15.3846H180.769V16.1699H181.71V9.25781H180.728V11.9879H180.655C180.386 11.4401 179.811 11.1159 179.13 11.1159C177.875 11.1159 177.067 12.1112 177.067 13.6817C177.067 15.2568 177.866 16.2521 179.13 16.2521ZM179.409 11.9971C180.231 11.9971 180.746 12.6499 180.746 13.6863C180.746 14.7272 180.235 15.3709 179.409 15.3709C178.578 15.3709 178.08 14.7363 178.08 13.6817C178.08 12.6362 178.582 11.9971 179.409 11.9971Z" />
          <path d="M188.075 16.2657C189.527 16.2657 190.417 15.2933 190.417 13.6817C190.417 12.0792 189.522 11.1022 188.075 11.1022C186.623 11.1022 185.728 12.0792 185.728 13.6817C185.728 15.2933 186.619 16.2657 188.075 16.2657ZM188.075 15.3938C187.221 15.3938 186.742 14.7683 186.742 13.6817C186.742 12.6043 187.221 11.9742 188.075 11.9742C188.924 11.9742 189.408 12.6043 189.408 13.6817C189.408 14.7637 188.924 15.3938 188.075 15.3938Z" />
          <path d="M191.716 16.1699H192.698V13.2617C192.698 12.4856 193.159 12.0016 193.885 12.0016C194.611 12.0016 194.958 12.3988 194.958 13.1978V16.1699H195.939V12.9512C195.939 11.7688 195.328 11.1022 194.218 11.1022C193.469 11.1022 192.976 11.4355 192.734 11.9879H192.661V11.1981H191.716V16.1699Z" />
          <path d="M200.46 9.96089V11.2209H199.67V12.0473H200.46V14.855C200.46 15.8138 200.894 16.1973 201.985 16.1973C202.177 16.1973 202.359 16.1744 202.519 16.147V15.3298C202.382 15.3435 202.295 15.3527 202.145 15.3527C201.656 15.3527 201.442 15.1244 201.442 14.6039V12.0473H202.519V11.2209H201.442V9.96089H200.46Z" />
          <path d="M203.837 16.1699H204.818V13.2663C204.818 12.513 205.266 12.0062 206.069 12.0062C206.763 12.0062 207.133 12.408 207.133 13.2023V16.1699H208.114V12.9604C208.114 11.7779 207.462 11.1068 206.402 11.1068C205.654 11.1068 205.129 11.4401 204.887 11.9971H204.809V9.25781H203.837V16.1699Z" />
          <path d="M211.655 11.9377C212.381 11.9377 212.856 12.4445 212.879 13.2252H210.368C210.423 12.449 210.929 11.9377 211.655 11.9377ZM212.874 14.8276C212.692 15.2157 212.285 15.4303 211.692 15.4303C210.907 15.4303 210.4 14.8779 210.368 14.0059V13.9511H213.879V13.6087C213.879 12.0473 213.043 11.1022 211.66 11.1022C210.258 11.1022 209.368 12.1112 209.368 13.7C209.368 15.2887 210.24 16.2657 211.664 16.2657C212.801 16.2657 213.596 15.7179 213.819 14.8276H212.874Z" />
          <path d="M125.842 22.3312C125.866 20.4821 126.859 18.7324 128.434 17.7639C127.441 16.3446 125.776 15.4446 124.044 15.3905C122.197 15.1966 120.406 16.4958 119.465 16.4958C118.505 16.4958 117.056 15.4097 115.496 15.4418C113.461 15.5075 111.565 16.6641 110.575 18.4425C108.448 22.1257 110.035 27.5389 112.073 30.5162C113.092 31.974 114.284 33.6025 115.843 33.5448C117.369 33.4815 117.939 32.5719 119.78 32.5719C121.605 32.5719 122.14 33.5448 123.731 33.5081C125.368 33.4815 126.399 32.0437 127.383 30.572C128.116 29.5332 128.679 28.3851 129.053 27.1703C127.129 26.3565 125.844 24.4202 125.842 22.3312Z" />
          <path d="M122.837 13.4325C123.73 12.3608 124.17 10.9834 124.063 9.59277C122.7 9.73602 121.44 10.3878 120.535 11.4183C119.65 12.4252 119.19 13.7784 119.277 15.1159C120.641 15.1299 121.982 14.4958 122.837 13.4325Z" />
        </g>
      </svg>
    </a>
  );
};

export default AppStoreButton;
