import { User as CheckoutComponentUser } from '@speechifyinc/checkout-component';
import { User } from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';
import { createNonPersistentStore } from 'lib/zustand';
import { UpgradeSource } from 'modules/subscription/utils/UpgradeSource';
import { logSegmentEvent } from 'utils/analytics';

type CheckoutState = {
  isModalOpen: boolean;
  userWithToken: CheckoutComponentUser | null;
};

const EXPIRE_TIME = 5 * 60 * 1000;
let lastRefresh = Date.now();

const getCheckoutUser = async (user: User | null) => {
  if (user) {
    const idToken = await user.getIdToken();
    lastRefresh = Date.now();
    return {
      uid: user.uid,
      email: user.email,
      idToken
    } as CheckoutComponentUser;
  }
};

const createCheckoutStore = () => {
  const store = createNonPersistentStore<CheckoutState>(
    () => {
      return {
        isModalOpen: false,
        userWithToken: null
      };
    },
    {
      isListeningScreenStore: false
    }
  );

  return store;
};

export const useCheckoutStore = createCheckoutStore();

const closeCheckoutModal = () => {
  useCheckoutStore.setState({
    isModalOpen: false
  });
};

const loadCheckoutToken = (refresh?: boolean) => {
  const state = useCheckoutStore.getState();
  if (!state.userWithToken || refresh || Date.now() > lastRefresh + EXPIRE_TIME) {
    return getCheckoutUser(firebaseAuth.currentUser).then(user => {
      useCheckoutStore.setState({
        userWithToken: user
      });
    });
  }
};

const openCheckoutModal = (source?: UpgradeSource) => {
  useCheckoutStore.setState({
    isModalOpen: true
  });
  logSegmentEvent('checkout_modal_opened', { source });
  loadCheckoutToken();
};

export const checkoutActions = {
  closeCheckoutModal,
  openCheckoutModal,
  loadCheckoutToken
};
