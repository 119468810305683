import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { checkoutActions } from 'modules/checkout/checkoutStore';

import { formatUpsellModalTypeAsString } from '../../utils';
import { UpsellModalType } from '../types';
import { useUpsellStore } from '../upsellStores';

export const showUpsellModal = async (modal: UpsellModalType) => {
  checkoutActions.loadCheckoutToken();
  useUpsellStore.setState({ activeModal: modal });
  logAnalyticsEvent(AnalyticsEventKey.upsellModalsContextual, { variant: formatUpsellModalTypeAsString(modal)! });
};
